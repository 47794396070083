<template>
  <div class="complaint-page">
    <div class="content">
      <h1 class="header">用户协议</h1>
      <div class="input-line">
        <p>欢迎使用本网站网盘搜索引擎！</p>
        <br />
        <p>
          请务必认真阅读和理解本《本网站用户协议》（以下简称“协议”）
          中规定的所有权利和限制。您一旦使用本站所有网络服务等行为将视为对本《协议》的接受，即表示您同意接受本《协议》各项条款的约束。如果您不同意本《协议》中的条款，您可以放弃使用本协议所涉及的相关服务。
        </p>
        <br />
        <p><b>1、权利声明</b></p>
        <br />
        <p>
          1.1. 本网站网站（网址：enfi.vip
          及包含所有子域名）的所有者及经营者，完全按照其发布的服务条款和操作规则提供基于互联网以及移动互联网的搜索引擎的相关产品与服务（以下简称“本网站”）。
        </p>
        <br />
        <p>
          1.2.
          任何企业和个人均不得非法使用本网站产品或通过非法手段干扰、破坏和影响本网站的正常服务。否则，我们将依法追究其法律责任。
        </p>
        <br />
        <p><b>2、本网站提供的服务</b></p>
        <br />
        <p>2.1. 搜索引擎服务</p>
        <br />
        <p>
          (1)
          本网站基于互联网行业普遍认同的“蜘蛛”（Spider）抓取数据而运作，即本网站的蜘蛛将从某些网页出发，通过网页间的相互链接关系，对互联网上的超链接进行访问和收集。由于互联网上的超链接数以亿计，本网站可能无法发现和收集全部链接，而是根据链接的特点选择其中的一部分。
        </p>
        <br />
        <p>
          (2)
          本网站将根据您在搜索框中键入的关键词及发出的搜索指令，根据独有的算法，为您生成搜索结果，搜索结果的内容为若干条和关键词相关的第三方网页链接。
        </p>
        <br />
        <p>2.2. 服务准则</p>
        <br />
        <p>
          (1)
          本网站会根据业务及技术的发展情况，随时变换、调整所提供的网络服务的具体内容。除非另有其它明示规定，本网站所推出的新产品、新功能、新服务，均受到本协议规范。
        </p>
        <br />
        <p>
          (2)
          本网站服务仅供用户个人用于非商业用途。如果要将与本网站服务有关或派生的任何资料、服务或软件用于销售或其它商业用途，则必须得到本网站的书面许可。
        </p>
        <br />
        <p>(3) 用户在使用本网站网络服务过程中，必须遵循以下原则：</p>
        <p>a). 遵守中国有关的法律和法规；</p>
        <p>b). 遵守所有与网络服务有关的网络协议、规定和程序；</p>
        <p>c). 不得为任何非法目的而使用网络服务系统；</p>
        <p>
          d).
          不得利用本网站网络服务系统进行任何可能对互联网或移动网正常运转造成不利影响的行为；
        </p>
        <p>
          e).
          不得利用本网站提供的网络服务上传、展示或传播任何虚假的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、庸俗淫秽的或其他任何非法的信息资料；
        </p>
        <p>
          f).
          不得侵犯其他任何第三方的专利权、著作权、商标权、名誉权或其他任何合法权益；
        </p>
        <p>g). 不得利用本网站网络服务系统进行任何不利于本网站的行为；</p>
        <br />
        <p>
          (3)
          如用户在使用本网站网络服务时违反任何上述规定，本网站或其授权的人有权要求用户改正或直接采取一切必要的措施以减轻用户不当行为造成的影响。
        </p>
        <br />
        <p>
          (4)
          用户如果对本网站服务有任何意见，或有如何改进的建议，可向本网站提出。
        </p>
        <br />
        <p>2.3. 服务变更、中断或终止</p>
        <br />
        <p>
          (1)
          本网站不断创新以向您提供最优体验。用户同意本网站有权根据业务发展情况随时变更、中断或终止部分或全部的网络服务而无需通知用户，也无需对任何用户或任何第三方承担任何责任；
        </p>
        <br />
        <p>
          (2)
          用户理解，本网站需要定期或不定期地对提供网络服务的平台（如互联网网站、移动网络等）或相关的设备进行检修或者维护，如因此类情况而造成网络服务在合理时间内的中断，本网站无需为此承担任何责任，但本网站尽可能事先进行通告。
        </p>
        <br />
        <p>
          (3)
          如用户违反本协议中规定的服务准则，本网站有权随时中断或终止向用户提供本协议项下的网络服务而无需对用户或任何第三方承担任何责任：
        </p>
        <br />
        <p><b>3. 权利许可</b></p>
        <br />
        <p>
          3.1.
          用户在使用本网站服务或通过服务提交、张贴或展示内容，即表示用户授予本网站一项永久性的、不可撤销的、世界范围内的、免交使用费的非排他性的许可。本网站有权以复制、改编、修改、翻译、发布、公开实施、公开展示及分发用户在服务上或通过服务提交、张贴或展示的任何内容。
        </p>
        <br />
        <p>
          3.2. 用户向本网站确认并保证拥有所有必要的权利、权力来授权上述许可。
        </p>
        <br />
        <p><b>4、免责与责任限制</b></p>
        <br />
        <p>
          4.1.
          本网站会以非人工检索方式自动生成到第三方网页的链接，本网站不存储、控制或修改该等第三方网页中的内容，且不对该等第三方网页或资源的可用性负责，亦不对该等网络或资源上的或从该等网站或资源获得的任何广告、产品或其他材料加以认可。
        </p>
        <br />
        <p>
          4.2.
          用户理解并同意自行承担自主选择、使用服务的风险，并且对于因使用服务而对计算机系统造成的损坏或数据的丢失，本网站不承担责任。
        </p>
        <br />
        <p>
          4.3.
          在适用法律允许的最大范围内，本网站明确表示不提供任何其他类型的保证，不论是明示的或默示的，包括但不限于适销性、适用性、可靠性、准确性、完整性、无病毒以及无错误的任何默示保证和责任。另外，在适用法律允许的最大范围内，本网站并不担保服务一定能满足您的要求，也不担保服务不会被修改、中断或终止，并且对服务的及时性、安全性、错误发生，以及信息是否能准确、及时、顺利的传送均不作任何担保。
        </p>
        <br />
        <p>
          4.4.
          在适用法律允许的最大范围内，本网站不就因您使用服务引起的或与服务有关的任何意外的、非直接的、特殊的、或间接的损害或请求（包括但不限于因人身伤害、因隐私泄漏、因未能履行包括诚信或合理谨慎在内的任何责任、因过失和因任何其他金钱上的损失或其他损失而造成的损害赔偿）承担任何责任。
        </p>
        <br />
        <p>
          4.5.
          本网站承诺尊重和保护权利人的知识产权。如果本网站提供的网页被权利人书面告知涉嫌侵犯其权益的，本网站有权根据中国法律法规和政府规范性文件采取措施移除前述内容或前述相关链接，而无须承担任何责任。
        </p>
        <br />
        <p>
          4.6.
          用户需要了解本网站服务的通用免责条款，请参见“本网站免责声明”（https://www.enfi.vip/about/policy）。
        </p>
        <br />
        <p>
          4.7. 用户违反本协议规定，对本网站造成损害的，
          本网站有权采取包括但不限于中断使用许可、停止提供服务、限制使用、法律追究等措施。
        </p>
        <br />
        <p><b>5、其他条款</b></p>
        <br />
        <p>
          5.1.
          如果本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，或违反任何适用的法律，则该条款被视为删除，但本协议的其余条款仍应有效并且有约束力。
        </p>
        <br />
        <p>
          5.2.
          本网站有权随时根据有关法律、法规的变化以及网站运营状况的调整等修改本协议。修改后的协议会在本网站网站（www.dashengpan.com）上公布。当发生有关争议时，以最新的协议文本为准。如果不同意改动的内容，用户可以不选择使用本网站提供的服务。如果用户继续使用本网站提供的服务，则视为您接受本协议的变动。
        </p>
        <br />
        <p>5.3. 本网站在法律允许的最大范围内对本协议拥有解释权与修改权。</p>
      </div>
      <div class="menu">
        <ul>
          <li><router-link to="/main/about/policy">免责声明</router-link></li>
          <li><router-link to="/main/about/agree">用户协议</router-link></li>
          <li><router-link to="/main/about/version">版权说明</router-link></li>
          <li><router-link to="/main/about/safe">净网声明</router-link></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_var.scss";
.complaint-page {
  min-height: 100%;
  background-color: #f8f8f8;
}
.content {
  margin: 20px auto;
  max-width: 800px;
  padding: 20px 40px;
  border-radius: 4px;
  border: 1px solid #ddd;
  background-color: #fff;
  font-size: 15px;
  line-height: 1.6;
  text-align: center;
  word-break: break-all;
  .complaint-image {
    width: 60px;
    margin-bottom: 10px;
  }
  .header {
    margin-bottom: 10px;
  }
  .input-line {
    text-align: left;
    margin-bottom: 15px;
  }
  .menu {
    width: 100%;
    border-top: 1px solid $theme-color;
    height: 40px;
    margin-top: 50px;
    ul {
      margin: 0px;
      list-style: none;
      li {
        float: right;
        margin-left: 30px;
        a {
          color: $theme-color;
        }
      }
    }
  }
}
@media (max-width: 700px) {
  .content {
    padding: 20px;
  }
}
</style>
